.workRightHeader{
    display: flex;
    padding: 0px 25px 5px 15px;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    position: relative;
}
.workRightHeader_name{
    font-family: Ubuntu-M;
    font-size: 17px;
    color: var(--text-primary);
    margin-right: 20px;
    min-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: max-content;
}
.workRightHeader_add_block{
    background: var(--color-brand);
    margin-left: 20px;
    border-radius: 100px;
    height: 35px;
    width: 35px;
    cursor: pointer;
    position: relative;
}

.tooltip{
    position: absolute;
    top: 24px;
    left: auto;
    right: 0;
    width: auto;
    display: none;
    background-color: #f9f9f9;
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px;
    z-index: 4000;
    color: var(--border-gray);
}

.tooltipWrap{
    top: 34px
}

.tooltipContainer{
    position: relative;
}

.tooltipContainerWrap:hover .tooltip{
    display: block;
}

.workRightHeader_add_block:hover .tooltip{
    display: block;
}

.workRightHeader_add{
    mask-image: url("../../../../svg/add-01.svg");
    background-color: white;
    height: 36px;
    width: 35px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
}
.workRightHeader_refresh{
    mask-image: url("../../../../svg/refresh-01.svg");
    background-color: white;
    height: 36px;
    width: 35px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
}
.WorkRightHeader_filter{
    cursor: pointer;
    margin-left: 10px;
    width: 36px;
}
.workRightHeader_block{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.searchDevice{
    position: absolute;
    right: 0;
    top: 120%;
    display: flex;
    align-items: center;
}

.deviceTrue{
    width: 18px;
    height: 18px;
    border: 2px solid grey;
    cursor: pointer;
}

.deviceTrueSelected{
    background-color: rgba(128, 128, 128, 0.1);
}

.WorkRightHeader_filter_printBlock{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.PrintBlock{
    position: absolute;
    top: 40px;
    border: 1px solid #50B160;
    border-radius: 5px;
    background: #f4f7fc;
    width: 219px;
}
.PrintBlockHeader{
    padding: 10px 10px 2px 14px;
}
.PrintBlockItem{
    cursor: pointer;
    padding: 7px 0px 7px 28px;
    font-family: Ubuntu-R;
}
.PrintBlockItem:hover{
    transition: 0.3s all ease;
    background-color: #a2e3df;
}
