
.model_copy {

    font-size: 15px;

    padding-bottom: 15px;
    max-width: 600px;
    min-height: 400px;

    background: var(--bg-secondary);
    border-radius: 5px;

    display: grid;
    align-content: space-between;
    grid-template-rows: 60px auto 50px;
}

.header {
    display: flex;
    justify-content: end;
    align-items: center;
}

.closeButton {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    margin: 10px;
    width: 35px;
    height: 35px;
    cursor: pointer;
}


.body {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
    min-height: 160px;
}

.footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.cancel_style {
    margin-left: 0px;
}


.text {
    font-size: 15px;
    font-family: Ubuntu-R;

}

.ico {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.ico img {
    width: 300px;
}

.ico span {
    font-family: Ubuntu-R;
}

.sites {

    font-family: Ubuntu-R;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sites a {
    margin-left: 10px;
    width: 100px;
}

.sites div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.sites span {
    text-align: end;
    width: 200px;
}
