.dropDown_container{
    display: flex;
    width: 607px;
    margin: 40px 0 0 0;
}

.lable{
    margin-right: 50px;
    width: 112px;
    font-size: 16px;
    font-family: Ubuntu-M;
    position: relative;
}

.container{
    margin: 0 5%;
}

.dropDown{
    width: 450px;
}

.iconsContainer{
    width: 1000px;
    max-height: 420px;
    display: grid;
    margin: 40px 0 0 0;
    grid-template-columns: repeat(10, 1fr);
    overflow-y: scroll;
    gap: 10px;
}

.itemIcon{
    border: 1px solid rgba(0, 0, 0, 0.504);
    height: 91px;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 2px;
    cursor: pointer;
}

.itemIcon:hover{
    background-color: #1100ff81;
}

.itemImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.rowAddTypeSample{
    width: 100%;

}

.rowAddTypeSamplebtn{
    margin: 0 auto;
    width: 76%;
    border: 2px solid #01b454;
    border-radius: 25px;
    padding: 5px;
    text-align: center;
    background-color: #01b454;
    color: white;
    cursor: pointer;
}

.hoverHintParent{
    position: relative;
    cursor: pointer;
}

.hoverHintParent:hover .hoverHint{
    display: block;
    position: absolute;
    z-index: 9000;
}

.hoverHint{
    position: absolute;
    top: 43px;
    left: 0;
    right: auto;
    width: auto;
    display: none;
    background-color: #f9f9f9;
    padding: 10px;
    border: none;
    margin-right: 10px;
    border-radius: 10px;
    color: var(--border-gray);
}

.hoverHint:hover{
    display: none !important;
}

.containerImg{
    width: 42px;
    height: 42px;
    overflow: hidden;
}
.containerMiniImg{
    width: 32px;
    height: 32px;
}

.selectType{
    background-color: #d9e7eb;
    border-radius: 10px;
    border: 2px solid gray !important;
}

.actionSample{
    display: flex;
    margin-bottom: 10px;
}

.actionSampleAdd{
    margin-right: 10px;
}

.titlePlace{
    margin-bottom: 10px;
}

.bg_blue{
    background-color: #1100ff5d;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
}

.input{
    border: none;
    border-bottom: 1px solid var(--border-gray);
    font-family: Ubuntu-R;
    font-size: 15px;
    padding: 2px;
    width: 100%;
    letter-spacing: .04em;
    background: none;
}

.inputContainer{
    width: 450px;
}

.footer {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 10px;
}

.textarea {
    font-family: Ubuntu-R;
    font-size: 14px;
    border: none;
    width: 100%;
    height: 40px;
    margin: 0px 0 10px 0px;
    color: var(--text-secondary);
    resize: none;
    display: inline-table;
}

.navSampleType{
    display: flex;
    width: 730px;
    background-color: #F1F7FC;
    border-radius: 10px;
    font-size: 20px;
    font-family: Ubuntu-R;
}

.navTab{
    width: 365px;
    text-align: center;
    padding: 12px 0;
    border-radius: 10px;
}

.navTab:hover{
    background-color: #a2e3df81;
    cursor: pointer;
}

.activeTab{
    background-color: #A2E3DF;
}

.activeTab:hover{
    
    background-color: #A2E3DF;
}

.rowAddTtype{
    display: flex;
    align-items: center;
}

.checkboxArrow {
    position: absolute;
    top: 35%;
    left: 20%;
}

.btnAddField{
    padding: 10px;
    border: 1px solid black;
    cursor: pointer;
}

.tabsContainer{
    padding: 0 5%;
    overflow-y: scroll;
    height: 90%;
}

.sampleTypeContainer{
    height: 100%;
}

.navContainer{
    padding: 20px 5%;
}