.workRightTable{
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
    display: block;
    height: calc(100% - 110px);
    min-height: 450px;
}
.workRightTable_sample{
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
    display: block;
    height: 78%;
    min-height: 450px;
}

.workRightTable_sample_filter{
    height: 54%;
}

.workRightTable_sample td{
    width: 9%;
}

.workRightTableHeader{
    font-family: Ubuntu-M;
    font-size: 17px;
    color: var(--text-primary);
    padding: 15px 0 10px 10px;
}
.workRightTableRow{
    transition: 0.2s all ease;
}

.workRightTableRow:hover{
    background: #DCE6E9;
    transition: 0.2s all ease;
}

.workRightTableRow:hover .workRightTableItem {
    background: #DCE6E9;
    transition: 0.2s all ease;
}

.workRightTableRow:hover > td > div > .workRightTableItem_Button{
    background-color: #1aba66;
    transition: 0.2s all ease;
}

.workRightTableItem_Button{
    transition: 0.2s all ease;
    background-color: white;
    height: 20px;
    width: 25px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
    mask-size: contain;
    float: left;
    cursor: pointer;
}
.workRightTableItem{
    transition: 0.2s all ease;
    font-family: Ubuntu-R;
    font-size: 14px;
    color: var(--text-primary);
    border-bottom: 1px solid #DCE6E9;
    border-top: 1px solid #DCE6E9;
    padding: 10px 10px 10px 10px;
}

.workRightTableItem_last{
}
.workRightTableItemCount{
}
.workRightTableItemColor{
    width: 30px;
    height: 30px;
    border-radius: 100%;
}
.workRightTableSortUpNot{
    -webkit-mask-image: url('../../../../svg/triangle-01.svg');
    width: 15px;
    height: 15px;
    background-color: slategrey;
    -webkit-mask-repeat: no-repeat;
    transform: rotate(180deg);
    margin: 0 0 2px 1px;
    cursor: pointer;
}
.workRightTableSortDownNot{
    -webkit-mask-image: url('../../../../svg/triangle-01.svg');
    width: 15px;
    height: 15px;
    background-color: slategrey;
    -webkit-mask-repeat: no-repeat;
    cursor: pointer;
}
.workRightTableSortUp{
    -webkit-mask-image: url('../../../../svg/triangle-01.svg');
    background-color: var(--color-brand);
    width: 15px;
    height: 15px;
    -webkit-mask-repeat: no-repeat;
    transform: rotate(180deg);
    margin: -5px 0 0 10px;
    cursor: pointer;
}
.workRightTableSortDown{
    -webkit-mask-image: url('../../../../svg/triangle-01.svg');
    background-color: var(--color-brand);
    width: 15px;
    height: 15px;
    margin: 4px 0 0 10px;
    -webkit-mask-repeat: no-repeat;
    cursor: pointer;
}
.workRightTableSortBlock{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 19px;
    align-items: center;
    width: 15px;
    margin-left: 10px;
}
.workRightTableHeaderBlock{
    display: flex;
    align-items: center;
}
.workRightTable_Image{
    width: 36px;
    height: 36px;
    border-radius: 100px;
    object-fit: cover;
}
.workRightTable_icon{
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.headerTableContainer { 
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.emptyBlock {
    width: 20%;
    color: transparent;
}
.workRightTableItemString{
    font-size: 15px;
    line-height: 1.4;
    font-weight: 800;
}
.workRightTableItemArray{
    display: flex;
    flex-direction: column;
    font-size: 15px;
    line-height: 1.4;
    font-weight: 800;
}

.containerImg{
    object-fit: contain;
    width: 80%;
    height: 80%;
}
.workRightTableItemSample{
    border-radius: 100%;
    background: #50B160;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    font-size: 25px;
    font-family: Ubuntu-R;
    border: 1px solid var(--border-gray);
    cursor: pointer;
}
.workRightTableItemSampleImg{
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    font-size: 25px;
    border: 1px solid var(--border-gray);
    cursor: pointer;
}

.sharedContainer {
    margin: 20px;
    display: flex;
    padding: 20px 30px;
    border-radius: 10px;
    background-color: rgba(217, 231, 234, 1);
    flex-direction: column;
}

.sharedBody {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.filterRow {
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin-bottom: 10px;
}
.filterSelector {
    position: relative;
    width: 250px;
    margin-right: 20px;
}
.infoItemValue {
    border: none;
    border-bottom: 1px solid #000;
    width: 100%;
    font-size: 16px;
    color: var(--text-primary);
    background-color: transparent;
    height: 21px;
}

.deleteIco {
    cursor: pointer;
    margin-left: 10px;
}

.footer {
    display: flex;
    flex-direction: row;
}
.footer button {
    margin-right: 20px;
}

.deviceTrueSelected{
    background-color: rgba(128, 128, 128, 0.1);
}
.deviceTrue{
    width: 18px;
    height: 18px;
    border: 2px solid grey;
    cursor: pointer;
}
