@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

:root {
    --color-brand: #01b454;

    --text-primary-brand: #009044;
    --text-primary: #13392b;
    --text-secondary: #59666d;

    --bg-primary: white;
    --bg-secondary: #d9e7eb;
    --bg-textarea: #F2F8FD;
    --bg-inactive: #ccc;

    --border-gray: #738087;
}

@font-face {
    font-family: Ubuntu-R;
    src: url(fonts/Ubuntu-R.ttf);
}

@font-face {
    font-family: Ubuntu-M;
    src: url(fonts/Ubuntu_Medium.ttf);
}

@font-face {
    font-family: GlifsIcons;
    src: url(fonts/glyphicons-halflings-regular.ttf);
}

.Right_block {
    width: calc(100% - 220px);
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.3s all ease;
    display: flex;
    flex-direction: column;
}

.Right_block_small {
    width: calc(100% - 70px);
}

input {
    outline: none;
}

textarea {
    outline: none;
}

select {
    outline: none;
}

[data-tooltip]::after {
    content: attr(data-tooltip);
    pointer-events: none;
    opacity: 0;
    transition: 1s;
    position: absolute;
    left: 0;
    top: 20px;
    transform: rotate(-45deg);
    z-index: 7;
    background: var(--bg-primary);
    padding: 10px;
    color: #c12d2d;
    font-family: Ubuntu-R;
    font-size: 14px;
    letter-spacing: 0.04em;
    line-height: 1.7;
}

[data-tooltip]:hover::after {
    opacity: 1;
}


/*Ubuntu`s font-weights:
 300 - light
 400 - regular
 500 - medium
 700 - bold
  */
body {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 17px;
}


body::-webkit-scrollbar {
    position: absolute;
    left: 14%;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
    background-color: #aebbbe;
    border-radius: 10px;
}

div::-webkit-scrollbar {
    position: absolute;
    left: 14%;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 10px;
}

div::-webkit-scrollbar-thumb {
    background-color: #aebbbe;
    border-radius: 10px;
}

table::-webkit-scrollbar {
    position: absolute;
    left: 14%;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 10px;
}

table::-webkit-scrollbar-thumb {
    background-color: #aebbbe;
    border-radius: 10px;
}

h4 {
    margin: 0;
}


/* DatePicker */


.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: var(--color-brand) !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: var(--color-brand)86 !important;
}
.react-datepicker {
    display: flex !important;
}

tr {
    justify-content: space-between;
    gap: 1em;
}

.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
    background-color: var(--color-brand) !important;
    border-radius: 4px;
}

.rdtPicker td.rdtToday:before {
    border-bottom: 7px solid var(--color-brand) !important;
}

.rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: var(--bg-primary) !important;
}

.switcher-with-icon {
    cursor: pointer !important;
}

.switcher-with-icon img {
    vertical-align: middle;
    margin-top: -3px;
}

.rdtPicker {
    width: auto;
    min-width: unset !important;
    position: relative;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.rdtPicker::before, .rdtPicker::after {
    content: '';
    position: absolute;
    left: 20px; top: -20px;
    border: 10px solid transparent;
    border-bottom: 10px solid #f9f9f9;
}

.rdtPicker::after {
    border-bottom: 10px solid white;
    top: -19px;
}

.rdtPicker {
    position: absolute;
}

.rdt .form-control {
    font-size: 15px;
    color: var(--text-primary);
    font-family: Ubuntu-R;
}

.rdtDays {
    width: 270px;
    margin-left: auto;
    margin-right: auto;
}

.rdtTime {
    width: 240px;
    margin-left: auto;
    margin-right: auto;
}

.rdtMonths, .rdtYears {
    width: 270px;
}

.rdtCounters {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 40px;
}

.rdtBtn:first-child, .rdtBtn:last-child {
    width: 43px;
    font-size: 0;
}

.rdtBtn:first-child {
    margin-bottom: 35px;
}

.rdtSwitch.switcher-with-icon {
    text-align: right;
}

.rdtSwitch.switcher-with-icon img {
    margin-right: 75px;
}

.rdtSwitch {
    font-size: 16px !important;
}

.rdtBtn:last-child, .rdtCounterSeparator {
    margin-top: 35px;
}

.rdtCounterSeparator {
    margin-left: 25px;
    margin-right: 25px;
}

.rdtBtn::before {
    width: 28px;
    font: normal normal normal 18px/1 GlifsIcons;
    font-size: 18px;
    color: var(--color-brand);
    text-align: center;
    vertical-align: middle;
}

.rdtBtn:hover {
    background: unset !important;
}

.rdtBtn:hover::before {
    color: black;
}

.rdtBtn:first-child::before {
    content: "\e113";
}

.rdtBtn:last-child::before {
    content: "\e114";
}

.rdtDays .dow, .rdtDay {
    font-size: 14px;
}

.rdtOpen + i {
    transform: rotate(180deg);
    margin-top: -3px;
}

.modalSure_icon {
    background: url("./svg/window_sure-01.svg");
}
.modalAttention_icon {
    background: url("./svg/window_attention-01.svg");
}
.modalOk_icon {
    background: url("./svg/window_ok-01.svg");
}
.modalInfo_icon {
    background: url("./svg/window_info-01.svg");
}

.close_icon {
    background: url("./svg/close-01.svg");
}

.plus_icon {
    background: url("./svg/add-white-01.svg");
}

.close_icon,
.plus_icon,
.modalSure_icon,
.modalAttention_icon,
.modalOk_icon,
.modalInfo_icon {
    background-position:  center;
    background-repeat: no-repeat;
    background-size: contain;
}


.button_default {
    width: 85%;
    display: flex;
    align-items: center;
    user-select: none;
}

.save_style {
    font-family: Ubuntu-R;
    font-size: 11pt;
    border-radius: 100px;
    border: 1px solid var(--color-brand);
    height: 34px;
    width: 131px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-brand);
    color: var(--bg-primary);
    cursor: pointer;
}

.cancel_style {
    font-family: Ubuntu-R;
    font-size: 11pt;
    border-radius: 100px;
    border: 1px solid var(--border-gray);
    height: 34px;
    width: 109px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--border-gray);
    cursor: pointer;
    margin-left: 20px;
}

.hover_hint_parent {
    position: relative;
    cursor: pointer;
}

.hover_hint {
    position: absolute;
    top: 26px;
    left: 0;
    right: auto;
    width: auto;
    display: none;
    background-color: #f9f9f9;
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px;
    z-index: 1000;
    color: var(--border-gray);
}
.hover_hint_parent:hover .hover_hint {
    display: block;
}
